import {
  Button as MuiButton,
  Card,
  CardContent,
  Divider as MuiDivider,
  Grid,
  Paper,
  Table,
  TableBody,
  Checkbox,
  TableCell,
  TableRow,
  Typography,
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import TableContainer from "@material-ui/core/TableContainer";
import EditIcon from "@material-ui/icons/Edit";
import Skeleton from "@material-ui/lab/Skeleton";
import { spacing } from "@material-ui/system";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import styled from "styled-components/macro";
import UnitForm from "./Form";
import style from "./styling/style";
import { getAllUnitTypes } from "modules/apl/api/actions";

const Divider = styled(MuiDivider)(spacing);
const Button = styled(MuiButton)(spacing);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function Elem(props) {
  const useStyles = style;
  const classes = useStyles();

  const [display, setDisplay] = React.useState(false);

  const toggleDisplay = () => {
    props.getAllUnitTypes();
    const shouldDisplay = !display;
    setDisplay(shouldDisplay);
  };

  return (
    <Card mb={6}>
      <CardContent xs={12}>
        <Grid container xs={12}>
          <Grid item xs={9} sm={9} md={9} lg={9}>
            <Typography variant="h6" gutterBottom>
              Unit Info
            </Typography>
          </Grid>
          <Grid
            item
            xs={3}
            sm={3}
            md={3}
            lg={3}
            className={classes.rightSideHeaderSubMenuToolBar}
          >
            <Button onClick={toggleDisplay}>
              <EditIcon
                fontSize="medium"
                className={classes.SubMenuToolBarIcon}
              />
            </Button>
            <Dialog
              open={display}
              TransitionComponent={Transition}
              fullWidth={"lg"}
              maxWidth={"lg"}
              keepMounted
              onClose={toggleDisplay}
              aria-labelledby="alert-dialog-slide-title"
              aria-describedby="alert-dialog-slide-description"
            >
              {props.units.data.unit ? (
                <DialogTitle id="alert-dialog-slide-title">
                  Unit {props.units.data.unit.name}{" "}
                </DialogTitle>
              ) : (
                <DialogTitle id="alert-dialog-slide-title">
                  Loading Unit.....{" "}
                </DialogTitle>
              )}
              <DialogContent>
                <UnitForm onUpdate={toggleDisplay} onCancel={toggleDisplay} />
              </DialogContent>
            </Dialog>
          </Grid>
        </Grid>
        <Divider className="mb-2 mt-2" my={1} />
        {!props.units.data.unit ? (
          <>
            <Skeleton
              variant="rect"
              height="40px"
              style={{
                backgroundColor: `#bdbfbe`,
                padding: 6,
                margin: 6,
                marginBottom: 3,
              }}
            />
            <Skeleton
              variant="rect"
              height="40px"
              style={{
                backgroundColor: `#bdbfbe`,
                padding: 6,
                margin: 6,
                marginBottom: 3,
              }}
            />
            <Skeleton
              variant="rect"
              height="40px"
              style={{
                backgroundColor: `#bdbfbe`,
                padding: 6,
                margin: 6,
                marginBottom: 3,
              }}
            />
            <Skeleton
              variant="rect"
              height="40px"
              style={{
                backgroundColor: `#bdbfbe`,
                padding: 6,
                margin: 6,
                marginBottom: 3,
              }}
            />
          </>
        ) : (
          <Grid container spacing={2} lg={12} className={classes.UnitInfoGrid}>
            <Grid item xs={12} lg={12} spacing={2}>
              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                  <TableBody>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <Typography variant="body1"> Address </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2">
                          {props.units.data.unit.address1 || ""}
                          <br />
                          {props.units.data.unit.address2 || ""}
                          <br />
                          {props.units.data.unit.city || ""},{" "}
                          {props.units.data.unit.state || ""}
                          <br />
                          {props.units.data.unit.zip || ""}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <Typography variant="body1"> Building No. </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2">
                          {props.units.data.unit.buildingNumber || ""}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <Typography variant="body1"> Sq Ft </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2">
                          {props.units.data.unit.sqFt || ""}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    {/*<TableRow>*/}
                    {/*  <TableCell component="th" scope="row">*/}
                    {/*    <Typography variant="body1"> Type </Typography>*/}
                    {/*  </TableCell>*/}
                    {/*  <TableCell>*/}
                    {/*    <Typography variant="body2">*/}
                    {/*      {props.units.data.unit.unitTypeDescription || ""}*/}
                    {/*    </Typography>*/}
                    {/*  </TableCell>*/}
                    {/*</TableRow>*/}
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <Typography variant="body1"> Beds </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2">
                          {props.units.data.unit.bedrooms || ""}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <Typography variant="body1"> Baths </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2">
                          {props.units.data.unit.baths || ""}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    {/*<TableRow>*/}
                    {/*  <TableCell component="th" scope="row">*/}
                    {/*    <Typography variant="body1"> Owner </Typography>*/}
                    {/*  </TableCell>*/}
                    {/*  <TableCell>*/}
                    {/*    <Typography variant="body2">*/}
                    {/*      {props.units.data.unit.ownerName || ""}*/}
                    {/*    </Typography>*/}
                    {/*  </TableCell>*/}
                    {/*</TableRow>*/}

                    {/* <TableRow>
                      <TableCell component="th" scope="row">
                        <Typography variant="body1"> Developer </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2">
                          {props.units.data.unit.developerName || ""}
                        </Typography>
                      </TableCell>
                    </TableRow> */}
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <Typography variant="body1"> Common Account </Typography>
                      </TableCell>
                      <TableCell>
                        <Checkbox
                          checked={
                            props.units.data.unit.commonAccount == 1
                              ? true
                              : false
                          }
                          disabled={true}
                          inputProps={{ "aria-label": "primary checkbox" }}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow></TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <Typography variant="body1"> Updated </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2">
                          {new Date(
                            props.units.data.unit.updated
                          ).toLocaleDateString() || ""}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        )}
      </CardContent>
    </Card>
  );
}

Elem.propTypes = {
  apl: PropTypes.object.isRequired,
  units: PropTypes.object.isRequired,
  getAllUnitTypes: PropTypes.func.isRequired,
};

function State(state) {
  return {
    apl: state.apl,
    units: state.units,
  };
}

export default connect(State, {getAllUnitTypes})(Elem);
